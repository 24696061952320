import React, { useEffect, useState } from 'react';
import './styles/Hero.css';
import Logo from './img/logo.jpg';

const Hero = () => {
    const [state, setState] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const navigation = [
        { title: "À Propos", path: "javascript:void(0)" },
        { title: "Avantages", path: "javascript:void(0)"},  
        { title: "Services", path: "javascript:void(0)" },
        { title: "Expérience", path: "javascript:void(0)" },
        { title: "Prix", path: "javascript:void(0)" },
        { title: "Contact", path: "javascript:void(0)" }
    ];

    function goToWho() {
        const element = document.getElementById('who');
        const scrollPosition = element.offsetTop - 200; 
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    }
    function goToServices() {
        const element = document.getElementById('services');
        const scrollPosition = element.offsetTop - 75; 
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    }
    function goToAvantages() {
        const element = document.getElementById('avantages');
        const scrollPosition = element.offsetTop - 250; 
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    }
    function goToExperience() {
        const element = document.getElementById('experience');
        const scrollPosition = element.offsetTop - 350; 
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    }
    function goToPrices() {
        const element = document.getElementById('prices');
        const scrollPosition = element.offsetTop + 100; 
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    }
    function goToContact() {
        const element = document.getElementById('contact');
        const scrollPosition = element.offsetTop + 50; 
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    }

    window.addEventListener('scroll', () => {
        const navbar = document.getElementById('navbar');
        if (window.scrollY > 20) {
            navbar.classList.add('scrolled');
        } else {
            navbar.classList.remove('scrolled');
        }
    }
    );
    

    useEffect(() => {
        const handleScroll = () => {
        const navbar = document.getElementById('navbar');
        if (window.scrollY > 20) {
            navbar.classList.add('scrolled');
        } else {
            navbar.classList.remove('scrolled');
        }
        }
    }, []);

    const Brand = () => (
        <div className="flex items-center justify-center py-5 md:block">
            <a href="home">
                <img
                    src={Logo}
                    width={90}
                    height={30}
                    alt="FMenoni Logo"
                />
            </a>
            <div className="md:hidden">
                <button className="menu-btn text-gray-500 hover:text-gray-800"
                    onClick={() => setState(!state)}
                >
                    {
                        state ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        )
                    }
                </button>
            </div>
        </div>
    );

    return (
        <div className="hero" id='hero'>
            <div className='relative bg-neutral-950'>
                <div className='absolute inset-0 blur-xl h-screen' style={{ background: "radial-gradient(circle at top left, rgba(17, 9, 46, 0.7) 0%, rgba(10, 10, 10, 0.1) 60%)", top: '-40%', left: '-40%' }}></div>
                <div className='absolute inset-0 blur-xl h-screen' style={{ background: "radial-gradient(circle at top right, rgba(17, 9, 46, 0.7) 0%, rgba(10, 10, 10, 0.1) 60%)", top: '-80%', left: '80%' }}></div>
                <div className='relative'>
                    <header>
                        <div className={`md:hidden ${state ? "mx-2 pb-5" : "hidden"}`}>
                            <Brand />
                        </div>
                        <nav
                            className={`z-9999 pb-3 md:pb-5 md:text-sm fixed top-0 inset-x-0 ${isScrolled ? 'shadow-lg rounded-xl border mx-2 mt-2 md:shadow-none md:border-none md:mx-0 md:mt-0 md:bg-transparent' : ''} ${state ? 'bg-white shadow-lg rounded-xl border mx-2 mt-2 md:shadow-none md:border-none md:mx-0 md:mt-0' : ''}`}
                            id='navbar'
                            style={{ height: '85px', lineHeight: '60px' }}
                        >
                            <div className="gap-x-14 items-center max-w-screen-xl mx-auto px-4 md:flex md:px-8">
                                <Brand />
                                <div className={`flex-1 items-center mt-2 md:mt-0 md:flex ${state ? 'block' : 'hidden'}`}>
                                    <ul className="flex-1 justify-end items-center space-y-6 md:flex md:space-x-6 md:space-y-0">
                                        {
                                            navigation.map((item, idx) => (
                                                <li key={idx} className="text-white hover:text-gray-400">
                                                    <a
                                                        href={item.path}
                                                        className="block"
                                                        onClick={() => {
                                                            if (item.title === 'À Propos') {
                                                                goToWho();
                                                            } else if (item.title === 'Services') {
                                                                goToServices();
                                                            } else if (item.title === 'Avantages') {
                                                                goToAvantages();
                                                            } else if (item.title === 'Expérience') {
                                                                goToExperience();
                                                            } else if (item.title === 'Prix') {
                                                                goToPrices();
                                                            } else {
                                                                goToContact();
                                                            }
                                                        }}>
                                                        {item.title}
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <section>
                        <div className="max-w-screen-xl mx-auto px-4 py-28 gap-12 text-gray-500 overflow-hidden md:px-8 md:flex" style={{ marginTop: '200px' }}>
                            <div className='flex-none space-y-5 max-w-xl' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <h1 className="text-4xl title font-extrabold sm:text-5xl text-white">
                                    FMenoni Web Development
                                </h1>
                                <p className="text-white">
                                    Une société qui vous accompagne dans la création de votre site web qui vous correspond et qui vous ressemble.
                                </p>
                                <div className='flex items-center gap-x-3 sm:text-sm'>
                                    <a href="javascript:void(0)" className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-gray-800 duration-150 hover:bg-gray-700 active:bg-gray-900 rounded-full md:inline-flex">
                                        En savoir plus
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className='flex-1 hidden md:block'>
                                <img src="https://raw.githubusercontent.com/sidiDev/remote-assets/c86a7ae02ac188442548f510b5393c04140515d7/undraw_progressive_app_m-9-ms_oftfv5.svg" className="max-w-xl md:max-w-2xl relative right-0 transform translate-x-1/4" />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Hero;
