import React from "react";
import whoImg from "./img/who.svg";

function Who() {
    return (
        <div className="who" id="who">
            <section className="py-14" style={{ margin: '200px' }}>
                <div className="max-w-screen-xl mx-auto md:px-8">
                    <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
                        <div className="flex-1 sm:hidden lg:block">
                            <img src={whoImg} className="md:max-w-lg sm:rounded-lg" alt="" />
                        </div>
                        <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
                            <h3 className="text-indigo-600 font-semibold">
                                FMenoni Web Developement
                            </h3>
                            <p className="little-title text-3xl sm:text-4xl">
                                Qui sommes nous ?
                            </p>
                            <p className="mt-3 text-gray-300">
                                FMenoni Web Developement est une société qui vous accompagne dans la création de votre site web qui vous correspond et qui vous ressemble. Nous vous proposons des solutions adaptées à vos besoins et à votre budget. 
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Who;