import React from "react";
import iMac from "./img/imac.png";

function Services() {
    return (
        <div className="services" id="services">
            <section className="scroll-pad-services">
                <div className="max-w-screen-xl mx-auto md:px-8">
                    <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
                        <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl align-items">
                            <h3 className="text-indigo-600 font-semibold">
                                FMenoni Web Developement
                            </h3>
                            <p className="little-title text-3xl sm:text-4xl">
                                Nos services
                            </p>
                            <p className="mt-3 text-gray-300">
                                FMenoni Web Developement, une société dévouée à la création de votre site web personnalisé et reflétant votre identité. Nous vous offrons des solutions sur mesure, parfaitement adaptées à vos besoins et à votre budget.
                            </p>
                        </div>
                        <div className="flex-1 sm:hidden lg:block">
                            <img src={iMac} className="iMac md:max-w-lg sm:rounded-lg " alt="iMac Showing Code" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Services;