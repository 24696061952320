import React from "react";
import Logo from "./img/logo.jpg";

const Footer = () => {
    const footerNavs = [
        {
            href: '#who',
            name: 'À Propos'
        },
        {
            href: '#avantages',
            name: 'Avantages'
        },
        {
            href: '#services',
            name: 'Services'
        },
        {
            href: '#experience',
            name: 'Expérience'
        },
        {
            href: '#prices',
            name: 'Prix'
        },

        {
            href: '#contact',
            name: 'Contact'
        },
        {
            href: 'legal',
            name: 'Mentions légales'
        }
    ]
    return (
        <div className="footer" id="footer">
            <footer className="text-gray-500 px-4 py-5 max-w-screen-xl mx-auto md:px-8">
                <div className="max-w-lg sm:mx-auto sm:text-center">
                    <img src={Logo} className="w-32 sm:mx-auto" />
                    <p className="leading-relaxed mt-2 text-[15px]">
                        FMenoni Web Developement, une société dévouée à la création de votre site web personnalisé et reflétant votre identité. Nous vous offrons des solutions sur mesure, parfaitement adaptées à vos besoins et à votre budget.
                    </p>
                </div>
                <ul className="items-center justify-center mt-8 space-y-5 sm:flex sm:space-x-4 sm:space-y-0">
                    {
                        footerNavs.map((item, idx) => (
                            <li className=" hover:text-gray-800">
                                <a key={idx} href={item.href}>
                                    {item.name}
                                </a>
                            </li>
                        ))
                    }
                </ul>
                <style jsx>{`
                .svg-icon path,
                .svg-icon polygon,
                .svg-icon rect {
                    fill: currentColor;
                }
            `}</style>
            </footer>
        </div>
    );
}

export default Footer;
                                    