import React from "react";
import { useEffect, useState } from "react";
import Logo from './img/logo.jpg';

const Legal = () => {

    const [state, setState] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const navigation = [
        { title: "À Propos", path: "home" },
        { title: "Avantages", path: "home" },  
        { title: "Services", path: "home" },
        { title: "Expérience", path: "home" },
        { title: "Prix", path: "home" },
        { title: "Contact", path: "home" }
    ];

    window.addEventListener('scroll', () => {
        const navbar = document.getElementById('navbar');
        if (window.scrollY > 20) {
            navbar.classList.add('scrolled');
        } else {
            navbar.classList.remove('scrolled');
        }
    }
    );
    

    useEffect(() => {
        const handleScroll = () => {
        const navbar = document.getElementById('navbar');
        if (window.scrollY > 20) {
            navbar.classList.add('scrolled');
        } else {
            navbar.classList.remove('scrolled');
        }
        }
    }, []);

    const Brand = () => (
        <div className="flex items-center justify-center py-5 md:block">
            <a href="home">
                <img
                    src={Logo}
                    width={90}
                    height={30}
                    alt="FMenoni Logo"
                />
            </a>
            <div className="md:hidden">
                <button className="menu-btn text-gray-500 hover:text-gray-800"
                    onClick={() => setState(!state)}
                >
                    {
                        state ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        )
                    }
                </button>
            </div>
        </div>
    );

    return (
        <div className="legal" id="legal">

                    <header>
                        <div className={`md:hidden ${state ? "mx-2 pb-5" : "hidden"}`}>
                            <Brand />
                        </div>
                        <nav
                            className={`z-9999 pb-3 md:pb-5 md:text-sm fixed top-0 inset-x-0 ${isScrolled ? 'shadow-lg rounded-xl border mx-2 mt-2 md:shadow-none md:border-none md:mx-0 md:mt-0 md:bg-transparent' : ''} ${state ? 'bg-white shadow-lg rounded-xl border mx-2 mt-2 md:shadow-none md:border-none md:mx-0 md:mt-0' : ''}`}
                            id='navbar'
                            style={{ height: '85px', lineHeight: '60px' }}
                        >
                            <div className="gap-x-14 items-center max-w-screen-xl mx-auto px-4 md:flex md:px-8">
                                <Brand />
                                <div className={`flex-1 items-center mt-2 md:mt-0 md:flex ${state ? 'block' : 'hidden'}`}>
                                    <ul className="flex-1 justify-end items-center space-y-6 md:flex md:space-x-6 md:space-y-0">
                                        {
                                            navigation.map((item, idx) => (
                                                <li key={idx} className="text-white hover:text-gray-400">
                                                    <a
                                                        href={item.path}
                                                        className="block"
                                                    >
                                                        {item.title}
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                        
            <section className="d-flex align-items-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className="max-w-screen-xl mx-auto md:px-8">
                    <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
                        <div className="max-w-xl mx-auto px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl align-items text-center">
                            <h3 className="text-indigo-600 font-semibold">
                                FMenoni Web Developement
                            </h3>
                            <p className="little-title text-3xl sm:text-4xl">
                                Mentions légales
                            </p>
                            <p className="mt-3 text-gray-300">
                                <strong>Nom :</strong> Menoni <br />
                                <strong>Prénom :</strong> Fabian <br />
                                <br />
                                <strong>Adresse :</strong> 128 Impasse du Salève 74800 Amancy <br />
                                <strong>Email :</strong> contact@fmenoni.com <br />
                                <br />
                                <strong>Illustration :</strong> <a href="https://storyset.com/">Storyset</a> <br />
                                <br />
                                <strong>Hébergeur :</strong> <br />
                                LWS (Ligne Web Services) <br />
                                10 rue Penthièvre <br />
                                75008 Paris <br />
                                Téléphone : +33 1 77 62 30 03 <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Legal;