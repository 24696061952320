import React from "react";

const Experience = () => {
    return (
        <div className="experience" id="experience">
            <section className="py-14">
                <div className="max-w-screen-xl mx-auto md:px-8">
                    <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
                        <div className="max-w-xl mx-auto px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl align-items text-center">
                            <h3 className="text-indigo-600 font-semibold">
                                FMenoni Web Developement
                            </h3>
                            <p className="little-title text-3xl sm:text-4xl">
                                Notre expérience
                            </p>
                            <p className="mt-3 text-gray-300">
                                FMenoni Web Developement ayant une courte expérience dans le domaine du développement web, nous avons déjà réalisé plusieurs projets pour des clients satisfaits. Ayant pour souhait l'acquisition de nouvelles compétences, nous sommes prêts à relever de nouveaux défis.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Experience;