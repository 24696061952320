import React, { useEffect, useState } from 'react';

const Prices = () => {
    const plans = [
        {
            name: "Site Vitrine",
            desc: "Un site web présentant l'entreprise et ses services pour une meilleure visibilité.",
            price: "500 - 1500",
            isMostPop: true,
            features: [
                "Plus rapide à réaliser",
                "Bien pour les petites entreprises",
                "Design simple et épuré",
                "Facile à mettre à jour",
                "Pas de fonctionnalités complexes",
                "Pas de base de données requise",
                "Pas de gestion de contenu requise",
            ],
        },
        {
            name: "Site Intéractif",
            desc: "Un site web interactif pour une meilleure expérience utilisateur avec création de compte utilisateur, posts...",
            price: "1500 - 3000",
            isMostPop: false,
            features: [
                "Plus de fonctionnalités",
                "Bien pour les moyennes entreprises",
                "Design plus complexe",
                "Facile à mettre à jour",
                "Base de données requise",
                "Gestion de contenu requise",
                "Création de compte utilisateur",
            ],
        },
        {
            name: "Site Personnalisé",
            desc: "Un site web sur mesure, adapté à vos besoins et à votre budget. Idéal pour les tout type d'entreprises.",
            price: "0 - 5000+",
            isMostPop: false,
            features: [
                "Fonctionnalités personnalisées",
                "Pour tout type d'entreprise",
                "Design sur mesure",
                "Facile à mettre à jour",
                "Base de données au choix",
                "Gestion de contenu au choix",
                "Rendez-vous pour discuter du projet",
            ],
        },
    ];
    function goToContact() {
    const element = document.getElementById('contact');
    if (element) {
        const scrollPosition = element.offsetTop + 50; 
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    } else {
        console.error('Element with id "contact" does not exist');
    }
}
    return (
        <div className="prices" id="prices" style={{ paddingTop: '200px' }}>
    <section className='py-14'>
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
            <div className='flex flex-col items-center text-center'>
                <div className='max-w-xl mx-auto px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl'>
                    <h3 className="text-indigo-600 font-semibold">
                        FMenoni Web Developement
                    </h3>
                    <h3 className='little-title text-3xl sm:text-4xl'>
                        Nos tarifs disponibles
                    </h3>
                    <div className='mt-3 max-w-xl'>
                        <p className="text-gray-300">
                            Nous vous proposons des tarifs adaptés à vos besoins et à votre budget. Vous avez le choix entre trois types de sites web : vitrine, interactif et personnalisé.
                        </p>
                    </div>
                </div>
            </div>
            <div className='z-50 mt-16 space-y-6 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3'>
                {
                    plans.map((item, idx) => (
                        <div key={idx} className='relative flex-1 flex items-stretch flex-col p-8 rounded-xl border-2 border-gray-700 bg-gray-800'>
                            <div>
                                <span className='text-indigo-400 font-medium'>
                                    {item.name}
                                </span>
                                <div className='mt-4 text-gray-300 text-3xl font-semibold'>
                                    {item.price} €
                                </div>
                            </div>
                            <ul className='py-8 space-y-3'>
                                {
                                    item.features.map((featureItem, idx) => (
                                        <li key={idx} className='flex items-center gap-5 text-gray-300'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='h-5 w-5 text-indigo-400'
                                                viewBox='0 0 20 20'
                                                fill='currentColor'>
                                                <path
                                                    fillRule='evenodd'
                                                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                                    clipRule='evenodd'></path>
                                            </svg>
                                            {featureItem}
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className="flex-1 flex items-end">
                                <button
                                    className='px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 btn-shadow'
                                    onClick={goToContact}
                                >
                                    Je suis intéressé(e)
                                </button>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </section>
    <div className="z--50 absolute inset-0 max-w-md mx-auto h-72 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)", top: '360%' }}></div>
</div>
    );
}

export default Prices;