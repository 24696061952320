import React from "react";

const Contact = () => {

    function envoieMail() {
        var identiteElement = document.getElementById('identite');
        var emailElement = document.getElementById('email');
        var phoneElement = document.getElementById('phone');
        var messageElement = document.getElementById('message');

        if (identiteElement && emailElement && phoneElement && messageElement) {
            var identite = identiteElement.value;
            var email = emailElement.value;
            var phone = phoneElement.value;
            var message = messageElement.value;

            window.location.href = `mailto:fabroc300@gmail.com?subject=Contact&body=Nom Prénom: ${identite}%0D%0AEmail: ${email}%0D%0ANuméro de téléphone: ${phone}%0D%0AMessage: ${message}`;
        } else {
            console.error('One or more elements do not exist');
        }
    }

    return (
        <div className="contact" id="contact">
            <main className="relative py-28" style={{ paddingTop: '200px' }}>
                <div className="relative z-10 max-w-screen-xl mx-auto text-gray-600 sm:px-4 md:px-8">
                    <div className="max-w-xl mx-auto px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl align-items text-center">
                        <h3 className="text-indigo-600 font-semibold">
                            FMenoni Web Developement
                        </h3>
                        <p className="little-title text-3xl sm:text-4xl">
                            Nous contacter
                        </p>
                        <p className="text-gray-300">
                            Vous avez des questions, des suggestions ou des remarques ? N'hésitez pas à nous contacter en remplissant le formulaire ci-dessous. Nous vous répondrons dans les plus brefs délais.
                        </p>
                    </div>
                    <div className="mt-12 mx-auto px-4 p-8 bg-white sm:max-w-lg sm:px-8 sm:rounded-xl">
                        <form
                            onSubmit={(e) => e.preventDefault()}
                            className="space-y-5"
                        >
                            <div>
                                <label className="font-medium">
                                    Nom Prénom
                                </label>
                                <input
                                    type="text"
                                    id="identite"
                                    placeholder="John Doe"
                                    required
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg"
                                />
                            </div>
                            <div>
                                <label className="font-medium">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="mail@exemple.com"
                                    required
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg"
                                />
                            </div>
                            <div>
                                <label className="font-medium">
                                    Numéro de téléphone
                                </label>
                                <div className="relative mt-2">
                                    <input
                                        type="tel"
                                        id="phone"
                                        placeholder="01 23 45 67 89"
                                        required
                                        className="w-full pl-[0.80rem] pr-3 py-2 appearance-none bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="font-medium">
                                    Message
                                </label>
                                <textarea
                                    required
                                    placeholder="Votre message ici..."
                                    id="message"
                                    className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg"
                                ></textarea>
                            </div>
                            <button
                                className="w-full px-4 py-2 text-white font-medium bg-gray-800 hover:bg-gray-700 active:bg-gray-900 rounded-lg duration-150"
                                onClick={envoieMail}
                            >
                                Envoyer
                            </button>
                        </form>
                    </div>
                </div>
                <div className='absolute inset-0 blur-[118px] max-w-lg h-[800px] mx-auto sm:max-w-3xl sm:h-[400px]' style={{ background: "linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)", top: '20%' }}></div>
            </main>
        </div>
    );
}

export default Contact;
