import React from "react";
import Hero from "./Hero";
import Who from "./Who";
import Avantage from "./Avantage";
import Services from "./Services"; 
import Prices from "./Prices";
import Experience from "./Experience";
import Contact from "./Contact";
import Footer from "./Footer";


function Home() {
    return (
        <div className="app">
        <Hero />
        <Who />
        <Avantage />
        <Services />
        <Experience />  
        <Prices />
        <Contact />
        <Footer />
        </div>
    );
    }

export default Home;