import React from "react";

function Avantage() {
    const features = [
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-zap"><path d="M4 14a1 1 0 0 1-.78-1.63l9.9-10.2a.5.5 0 0 1 .86.46l-1.92 6.02A1 1 0 0 0 13 10h7a1 1 0 0 1 .78 1.63l-9.9 10.2a.5.5 0 0 1-.86-.46l1.92-6.02A1 1 0 0 0 11 14z" /></svg>,
            title: "Rapidité de service",
            desc: "Nous vous offrons des services de qualité, dans les délais les plus courts possible."
        },
        {
            icon:
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevrons-up"><path d="m17 11-5-5-5 5"/><path d="m17 18-5-5-5 5"/></svg>,
            title: "Performance garantie",
            desc: "Les sites réalisés sont performants et optimisés pour un meilleur référencement."
        },
        {
            icon:
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shield-check"><path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"/><path d="m9 12 2 2 4-4"/></svg>,
            title: "Qualité optimale",
            desc: "La qualité est au coeur de nos préoccupations. Nous vous offrons des services de qualité."
        },
    ];
    return (
        <div className="avantages" id="avantages">
            <section className="relative py-28" style={{ marginTop: '-50px' }}>
                <div className="relative z-10 max-w-screen-xl mx-auto px-4 space-y-3 mt-6 text-gray-300 justify-between gap-24 lg:flex md:px-8 px-4 space-y-3 mt-6 sm:px-0 md:mt-0">
                    <div className="max-w-xl">
                        <h3 className="text-indigo-600 font-semibold">
                            FMenoni Web Developement
                        </h3>
                        <h3 className="little-title text-3xl sm:text-4xl">
                            Des avantages uniques
                        </h3>
                        <p className="mt-3">
                            Chez FMenoni Web Developement, nous mettons un point d'honneur à vous offrir des services de qualité.
                        </p>
                    </div>
                    <div className="mt-12 lg:mt-0">
                        <ul className="grid gap-8 sm:grid-cols-2">
                            {
                                features.map((item, idx) => (
                                    <li key={idx} className="flex gap-x-4">
                                        <div className="flex-none w-12 h-12 bg-gray-700 text-cyan-400 rounded-lg flex items-center justify-center">
                                            {item.icon}
                                        </div>
                                        <div>
                                            <h4 className="text-lg text-gray-100 font-semibold">
                                                {item.title}
                                            </h4>
                                            <p className="mt-3">
                                                {item.desc}
                                            </p>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)", top: '10%' }}></div>
            </section>
        </div>
    );
}

export default Avantage;